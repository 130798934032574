/*

  React Responsive Select - Default styles

*/

.rrs {
  font-family: sans-serif;
  position: relative;
  box-sizing: border-box;
  font-family: 'Cera Pro', sans-serif;
}

.rrs *,
.rrs *:before,
.rrs *:after {
  box-sizing: border-box;
}

.rrs__button {
  color: #ffffff;
  position: relative;
  cursor: pointer;
  line-height: 28px;
  border-radius: 4px;
  border: 2px solid #bca169;
  outline: 0;
  min-width: 156px;
}

@media screen and (min-width: 443px) {
  .rrs__button {
    line-height: 44px;
    min-width: 256px;
  }
}

.rrs__button--disabled {
  color: #999999;
  cursor: default;
}

.rrs__button:focus {
  outline: 0;
}

.rrs__button + .rrs__options {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 2;
  border-radius: 0 0 4px 4px;
  top: 46px;
  left: 0;
  right: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  border: 2px solid #bca169;
}

.rrs--options-visible .rrs__options {
  height: auto;
  visibility: visible;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 230px;
  background-color: #0b1d26;
}

/*

  Mobile Close Icon

*/

.mobile-close {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rrs__option {
  cursor: pointer;
  padding: 0.75rem 1rem;
  margin: 0;
}

.rrs__option * {
  pointer-events: none;
}

.rrs__option:focus {
  outline: none;
}

.rrs__option:hover {
  background: rgba(255, 255, 255, 0.02);
}

.rrs__option.rrs__option--next-selection {
  background: rgba(255, 255, 255, 0.05);
}

.rrs__option.rrs__option--disabled {
  color: #999999;
  cursor: default;
}

.rrs__option.rrs__option--header {
  color: #ffffff;
  cursor: default;
  font-size: 0.7rem;
  /*font-weight: 700;*/
  text-transform: uppercase;
  top: 0;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .rrs__option.rrs__option--header {
    position: sticky;
  }
}

.rrs__label {
  padding: 0 2rem 0 1rem;
  display: inline-flex;
  height: 100%;
  width: 100%;
  max-width: 100%;
  font-size: inherit;
  background: transparent;
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rrs__label * {
  pointer-events: none;
}

.rrs--options-visible .rrs__label,
.rrs__button:focus .rrs__label {
  outline: 0;
}

.rrs--options-visible .rrs__label {
  border-radius: 4px 4px 0 0;
}

/*

  Multiselect overrides

*/

.rrs__multiselect-label {
  display: inline-flex;
  max-width: 100%;
  line-height: 1;
}

.rrs__multiselect-label__text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rrs__multiselect-label__badge {
  border: 1px solid #ccc;
  padding: 1px 6px;
  margin: 0 0 0 4px;
  border-radius: 8px;
  color: #666;
  font-size: 11px;
  vertical-align: middle;
  display: inline-block;
}

/*

  Checkbox

*/

.rrs .checkbox {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  top: -1px;
  border: 1px solid #ccc;
  margin: 2px 8px 2px 0;
  border-radius: 4px;
}

.rrs__option.rrs__option--selected .checkbox {
  border: 1px solid #ffffff;
}

.rrs .checkbox-icon {
  fill: transparent;
  position: absolute;
  left: 1px;
  top: 1px;
}

.rrs__option.rrs__option--selected .checkbox-icon {
  fill: #ffffff;
}

/*

  Caret Icon

*/

.rrs .caret-icon {
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  fill: #bca169;
}

@media screen and (min-width: 443px) {
  .rrs .caret-icon {
    top: 1.25rem;
  }
}

.rrs--options-visible .caret-icon {
  transform: rotate(180deg);
}

/*

  Badge

*/

.badge {
  background: #f1f1f1;
  padding: 0.25rem 1rem;
  margin: 2px 10px 2px 0;
  border-radius: 3px;
}

/*

  Open in a modal when smaller potentially touch screen

*/

@media screen and (max-width: 442px) {
  .rrs {
    position: static;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .rrs.rrs--options-visible:after {
    content: '';
    cursor: pointer;
    position: fixed;
    animation: fadeIn 0.3s ease forwards;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(11, 29, 38, 0.9);
    backdrop-filter: blur(8px);
  }

  .rrs--options-visible .rrs__options {
    max-height: initial;
    position: fixed;
    font-size: 1.25rem;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0;
    padding: 1rem;
    border-radius: 4px;
    z-index: 3;
    top: 0;
  }

  /*

    Mobile Close Icon

  */

  .mobile-close {
    display: block;
    cursor: pointer;
    line-height: 1;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
    -webkit-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }

  .mobile-close__icon {
    fill: #fff;
    padding: 0.5rem;
    width: 2rem;
    height: 2rem;
    border-radius: 4px;
  }
}
