@font-face {
  font-family: 'Cera';
  src: url('/static/fonts/cera/thin.eot');
  src: url('/static/fonts/cera/thin.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/thin.woff2') format('woff2'), url('/static/fonts/cera/thin.woff') format('woff'), url('/static/fonts/cera/thin.ttf') format('truetype');

  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

// @font-face {
//   font-family: 'Cera';
//   src: url('/static/fonts/cera/Cera-Thin Italic-Web.eot');
//   src: url('/static/fonts/cera/Cera-Thin Italic-Web.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/Cera-Thin Italic-Web.woff2') format('woff2'), url('/static/fonts/cera/Cera-Thin Italic-Web.woff') format('woff'), url('/static/fonts/cera/Cera-Thin Italic-Web.ttf') format('truetype');
//
//   font-display: swap;
//   font-weight: 100;
//   font-style: italic;
// }

@font-face {
  font-family: 'Cera';
  src: url('/static/fonts/cera/light.eot');
  src: url('/static/fonts/cera/light.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/light.woff2') format('woff2'), url('/static/fonts/cera/light.woff') format('woff'), url('/static/fonts/cera/light.ttf') format('truetype');

  font-display: swap;
  font-weight: 200;
  font-style: normal;
}

// @font-face {
//   font-family: 'Cera Light Italic';
//   src: url('/static/fonts/cera/Cera-Light Italic-Web.eot');
//   src: url('/static/fonts/cera/Cera-Light Italic-Web.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/Cera-Light Italic-Web.woff2') format('woff2'), url('/static/fonts/cera/Cera-Light Italic-Web.woff') format('woff'), url('/static/fonts/cera/Cera-Light Italic-Web.ttf') format('truetype');
//
//   font-display: swap;
//   font-weight: 200;
//   font-style: italic;
// }

@font-face {
  font-family: 'Cera';
  src: url('/static/fonts/cera/regular.eot');
  src: url('/static/fonts/cera/regular.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/regular.woff2') format('woff2'), url('/static/fonts/cera/regular.woff') format('woff'), url('/static/fonts/cera/regular.ttf') format('truetype');

  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

// @font-face {
//   font-family: 'Cera';
//   src: url('/static/fonts/cera/Cera-Regular Italic-Web.eot');
//   src: url('/static/fonts/cera/Cera-Regular Italic-Web.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/Cera-Regular Italic-Web.woff2') format('woff2'), url('/static/fonts/cera/Cera-Regular Italic-Web.woff') format('woff'), url('/static/fonts/cera/Cera-Regular Italic-Web.ttf') format('truetype');
//
//   font-display: swap;
//   font-weight: 300;
//   font-style: italic;
// }

@font-face {
  font-family: 'Cera';
  src: url('/static/fonts/cera/medium.eot');
  src: url('/static/fonts/cera/medium.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/medium.woff2') format('woff2'), url('/static/fonts/cera/medium.woff') format('woff'), url('/static/fonts/cera/medium.ttf') format('truetype');

  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

// @font-face {
//   font-family: 'Cera Medium Italic';
//   src: url('/static/fonts/cera/Cera-Medium Italic-Web.eot');
//   src: url('/static/fonts/cera/Cera-Medium Italic-Web.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/Cera-Medium Italic-Web.woff2') format('woff2'), url('/static/fonts/cera/Cera-Medium Italic-Web.woff') format('woff'), url('/static/fonts/cera/Cera-Medium Italic-Web.ttf') format('truetype');
//
//   font-display: swap;
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Cera';
  src: url('/static/fonts/cera/bold.eot');
  src: url('/static/fonts/cera/bold.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/bold.woff2') format('woff2'), url('/static/fonts/cera/bold.woff') format('woff'), url('/static/fonts/cera/bold.ttf') format('truetype');

  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

// @font-face {
//   font-family: 'Cera Bold Italic';
//   src: url('/static/fonts/cera/Cera-Bold Italic-Web.eot');
//   src: url('/static/fonts/cera/Cera-Bold Italic-Web.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/Cera-Bold Italic-Web.woff2') format('woff2'), url('/static/fonts/cera/Cera-Bold Italic-Web.woff') format('woff'), url('/static/fonts/cera/Cera-Bold Italic-Web.ttf') format('truetype');
//
//   font-display: swap;
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Cera';
  src: url('/static/fonts/cera/black.eot');
  src: url('/static/fonts/cera/black.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/black.woff2') format('woff2'), url('/static/fonts/cera/black.woff') format('woff'), url('/static/fonts/cera/black.ttf') format('truetype');

  font-display: swap;
  font-weight: 900;
  font-style: normal;
}
//
// @font-face {
//   font-family: 'Cera Black Italic';
//   src: url('/static/fonts/cera/Cera-Black Italic-Web.eot');
//   src: url('/static/fonts/cera/Cera-Black Italic-Web.eot#iefix') format('embedded-opentype'), url('/static/fonts/cera/Cera-Black Italic-Web.woff2') format('woff2'), url('/static/fonts/cera/Cera-Black Italic-Web.woff') format('woff'), url('/static/fonts/cera/Cera-Black Italic-Web.ttf') format('truetype');
//
//   font-display: swap;
//   font-weight: normal;
//   font-style: normal;
// }
