@import "fonts.scss";

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #0b1d26;
  color: #ffffff;
  font-family: 'Cera', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 768px) {
    padding: 0 40px;
  }
}

#__next {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pointer {
  cursor: pointer;
}
